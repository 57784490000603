import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const InputDataModal = ({ showModal, setShowModal, onSubmit, message, platMobil }) => {
    const [formData, setFormData] = useState({
        plat_mobil: '',
        warna_mobil: '',
        name: '',
        phone_number: '',
        date_of_birth: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        // Call the onSubmit function passed as a prop
        onSubmit(formData);
        setFormData({
            plat_mobil: '',
            merk_mobil: '',
            warna_mobil: '',
            name: '',
            phone_number: '',
            date_of_birth: '',
        })
        // Close the modal after submission
        setShowModal(false);
    };
    
    useEffect(() => {
        setFormData(prevFormData => ({
            ...prevFormData,
            plat_mobil: platMobil
        }));
    },[platMobil])

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Input Member Data</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><i>{message}</i></p>
                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formPlatMobil">
                        <Form.Label>Plat Mobil *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Plat Mobil"
                            name="plat_mobil"
                            value={formData.plat_mobil}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formMerkMobil">
                        <Form.Label>Merk Mobil *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Merk Mobil"
                            name="merk_mobil"
                            value={formData.merk_mobil}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formWarnaMobil">
                        <Form.Label>Warna Mobil *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Warna Mobil"
                            name="warna_mobil"
                            value={formData.warna_mobil}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formName">
                        <Form.Label>Name *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Name"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formPhoneNumber">
                        <Form.Label>Phone Number *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Enter Phone Number"
                            name="phone_number"
                            value={formData.phone_number}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formDateOfBirth">
                        <Form.Label>Date of Birth (Optional)</Form.Label>
                        <Form.Control
                            type="date"
                            name="date_of_birth"
                            value={formData.date_of_birth}
                            onChange={handleChange}
                        />
                    </Form.Group>

                    <Button className="mt-2" variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default InputDataModal;