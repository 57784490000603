import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';

const PaymentMethodModal = ({ show, onHide, onConfirm }) => {
  // Initialize selectedMethod from localStorage if available, otherwise default value
  const getInitialSelectedMethod = () => {
    const savedMethod = localStorage.getItem('selectedMethod');
    return savedMethod ? JSON.parse(savedMethod) : { name: '', value: '' };
  };

  const [selectedMethod, setSelectedMethod] = useState(getInitialSelectedMethod);

  const paymentMethods = [
    { name: 'Cash', value: 'cash' },
    { name: 'QRIS', value: 'qris' },
    { name: 'BCA', value: 'bca' },
  ];

  // Function to handle payment method selection
  const handleSelect = (method) => {
    setSelectedMethod(method);
    localStorage.setItem('selectedMethod', JSON.stringify(method)); // Save selection to localStorage
  };

  // Clear selectedMethod when modal is closed if needed
  const handleClose = () => {
    setSelectedMethod({ name: '', value: '' });
    localStorage.removeItem('selectedMethod'); // Optionally clear from localStorage
    onHide();
  };

  useEffect(() => {
    // Set selected method from localStorage if present on component mount
    const savedMethod = localStorage.getItem('selectedMethod');
    if (savedMethod) {
      setSelectedMethod(JSON.parse(savedMethod));
    }
  }, []);

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Select Payment Method</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          {paymentMethods.map((method) => (
            <Form.Check
              key={method.value}
              type="radio"
              label={method.name}
              name="paymentMethod"
              value={method.value}
              checked={selectedMethod.value === method.value}
              onChange={() => handleSelect(method)}
            />
          ))}
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
        <Button
          variant="primary"
          onClick={() => onConfirm(selectedMethod)}
          disabled={!selectedMethod.value} // Disable button if no method is selected
        >
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default PaymentMethodModal;
