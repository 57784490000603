import logo from './logo.svg';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'bootstrap/dist/css/bootstrap.css';
import './App.css';
import {Routes, Route, useSearchParams} from 'react-router-dom';

import PrivateComponent from './components/private-component';
import Home from './pages/home';
import Login from './pages/login';
import DashboardMain from './pages/dashboard/main';
import MenuContentCarWashWithCustomDate from './components/secretAdmin/menu-contents';

function App() {
  return (
    <div className="App">
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path="/fnb" element={<DashboardMain />} />
          <Route path="/login" element={<Login />} />
          <Route path='/carwash' element={<DashboardMain />} />
          <Route path='/hehe' element={<DashboardMain />} />
          {/* <Route path="/report" element={<Report />} /> */}
          {/* <Route element={<PrivateComponent />}> */}
            {/* <Route path="/dashboard/order" element={<DashboardMain />} /> */}
          {/* </Route> */}
        </Routes>
    </div>
  );
}

export default App;
