import React, { useEffect, useState, useContext } from 'react';
import { useSearchParams, useNavigate } from 'react-router-dom';

import { AppContext } from '../../Context';

import CenteredModal from "../../components/modal";
import PaymentMethodModal from "../paymentMethodModal"
import DateRangeModal from '../date-range-modal';

import printInvoice from '../../utils/printInvoice';
import Currency from "../../utils/currency";
import generateSalesChartCarwash from '../../utils/generateSalesChartCarwash';
import sendApiRequest from '../../utils/sendReq';

import moment from "moment";
import { Button } from 'react-bootstrap';
import jsPDF from 'jspdf';
import axios from 'axios';

import MenuContent from './menu-content';


const BillsContentCarwash = () => {
    const [searchParams] = useSearchParams();
    const { order } = useContext(AppContext);
    const [stateBills, setStateBills] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDateModal, setDateShowModal] = useState(false);
    const [children, setChildren] = useState('');
    const [title, setTitle] = useState('');
    const [sizeModal, setSizeModal] = useState('');
    const [styleModal, setStyleModal] = useState({});
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [currentBill, setCurrentBill] = useState(null);
    const [currentOrderItemsList, setCurrentOrderItemsList] = useState([]);
    const [orderItemsListParam, setOrderItemsListParam] = useState(null);
    const [billsParam, setBillsParam] = useState(null)
    const router = useNavigate();
    const [freeOrderCarwash, setFreeOrderCarwash] = useState([])

    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

    const handleDateChange = (startDate, endDate) => {
        setDateRange({ startDate, endDate });
        onClickPrintReport(startDate, endDate);
    };

    const [showModalPayment, setShowModalPayment] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const handleConfirmPaymentModal = (method) => {
        if (method && method.value) {
          setPaymentMethod(method);
          setShowModalPayment(false);
          onClickCompletePayment(orderItemsListParam, billsParam);
          console.log('Selected payment method:', method);
        } else {
          console.error("No valid payment method selected.");
        }
      };

      const onClickPrintReport = async (startDate, endDate) => {
        const { isToday, datePayload, typeOfReport, billsDataForSelectedRange } = await prepareData(startDate, endDate);
    
        if (billsDataForSelectedRange.length === 0) {
            alert('No orders found for the selected date range');
            return;
        }
    
        const menuData = await fetchMenuData();
        const { items, totalSales, minusServiceFee, totalSalesByPaymentMethodWithServiceFee } = calculateSalesData(billsDataForSelectedRange, menuData);
        const totalTransaction = billsDataForSelectedRange.filter((val) => val.status !== 'pending').length;
        const totalServiceFee = totalSales * process.env.REACT_APP_PERCENTAGE_FEE_CARWASH - minusServiceFee;
    
        const dataPayload = createDataPayload(items, totalSales, totalServiceFee, totalTransaction, typeOfReport, startDate, endDate);
        
        const topItems = items.sort((a, b) => b.quantity - a.quantity).slice(0, 10);
        const chartImage = await generateSalesChartCarwash(topItems);
    
        generatePDFReport(startDate, endDate, totalSales, totalServiceFee, totalTransaction, chartImage, totalSalesByPaymentMethodWithServiceFee, items);
        await sendReport(dataPayload);
    };
    
    // Helper Functions
    
    const prepareData = async (startDate, endDate) => {
        const startDateFormatted = moment(startDate).format('DDMMYYYY');
        const endDateFormatted = moment(endDate).format('DDMMYYYY');
        const currentDateFormatted = moment().format('DDMMYYYY');
        const isToday = startDateFormatted === currentDateFormatted && endDateFormatted === currentDateFormatted;
    
        const datePayload = isToday ? startDate : `${startDate} - ${endDate}`;
        const typeOfReport = isToday ? "Daily" : "Custom Range";
    
        const billsDataForSelectedRange = isToday
            ? JSON.parse(localStorage.getItem("orderDataCarwash"))?.[currentDateFormatted]?.data || []
            : await fetchOrdersByDateRange(startDate, endDate);
    
        return { isToday, datePayload, typeOfReport, billsDataForSelectedRange };
    };
    
    const fetchMenuData = async () => {
        return await fetch('/menu-carwash.json').then(response => response.json());
    };
    
    // const calculateSalesData = (billsData, menuData) => {
    //     console.log(billsData)
    //     let items = [];
    //     let totalSales = 0;
    //     let minusServiceFee = 0;
    //     const totalPriceByPaymentMethod = {};
    //     const totalSalesByPaymentMethodWithServiceFee = {};
    //     const totalSalesByPaymentMethod = {};
    
    //     billsData.forEach((bill) => {
    //         const paymentMethod = bill.metode_pembayaran;
    //         bill.order_items.forEach((val) => {
    //             const itemIndex = items.findIndex((itm) => itm.id === val.id);
    //             const itemName = val.item_name;

    //             const itemTotalWithServiceFee = ["Free Coffee", "Voucher 25k"].includes(val.item_name)
    //                 ? val.sub_total_price
    //                 : val.sub_total_price * 1.05;
    
    //             totalSalesByPaymentMethodWithServiceFee[paymentMethod] = (totalSalesByPaymentMethodWithServiceFee[paymentMethod] || 0) + itemTotalWithServiceFee;
    //             totalPriceByPaymentMethod[paymentMethod] = (totalPriceByPaymentMethod[paymentMethod] || 0) + val.sub_total_price;
    
    //             // Accumulate total sales by payment method for the pie chart
    //             totalSalesByPaymentMethod[paymentMethod] = (totalSalesByPaymentMethod[paymentMethod] || 0) + val.sub_total_price;
    
    //             if (itemIndex === -1) {
    //                 if (itemName.includes('Free Coffee') || itemName.includes('Voucher 25k')) {
    //                     minusServiceFee += val.sub_total_price * process.env.REACT_APP_PERCENTAGE_FEE_CARWASH;
    //                 }
    //                 items.push({ id: val.id, item_name: itemName, quantity: val.quantity, item_price: val.item_price, sub_total_price: val.sub_total_price });
    //                 totalSales += val.sub_total_price;
    //             } else {
    //                 if (itemName.includes('Free Coffee') || itemName.includes('Voucher 25k')) {
    //                     items[itemIndex].quantity += val.quantity;
    //                     items[itemIndex].sub_total_price += val.sub_total_price;
    //                     minusServiceFee += val.sub_total_price * process.env.REACT_APP_PERCENTAGE_FEE_CARWASH;
    //                 } else {
    //                     items[itemIndex].quantity += val.quantity;
    //                     items[itemIndex].sub_total_price += val.sub_total_price;
    //                 }
    //                 totalSales += val.sub_total_price;
    //             }
    //         });
    //     });
    //     console.log(totalSalesByPaymentMethod)
    //     return { items, totalSales, minusServiceFee, totalSalesByPaymentMethodWithServiceFee };
    // };
    const calculateSalesData = (billsData, menuData) => {
        let items = [];
        let totalSales = 0;
        let minusServiceFee = 0;
        const totalSalesByPaymentMethodWithServiceFee = {};
    
        billsData.forEach((bill) => {
            const paymentMethod = bill.metode_pembayaran;
            
            bill.order_items.forEach((val) => {
                // Create a unique identifier combining item name and shift name
                const itemIdentifier = `${val.item_name}-${bill.shift_name}`;
                const itemIndex = items.findIndex((itm) => itm.id === itemIdentifier);
    
                const itemTotalWithServiceFee = ["Free Coffee", "Voucher 25k"].includes(val.item_name)
                    ? val.sub_total_price
                    : val.sub_total_price * 1;
    
                totalSalesByPaymentMethodWithServiceFee[paymentMethod] = 
                    (totalSalesByPaymentMethodWithServiceFee[paymentMethod] || 0) + itemTotalWithServiceFee;
    
                if (itemIndex === -1) {
                    // Treat as a new entry if no existing match is found
                    items.push({
                        id: itemIdentifier, 
                        item_name: val.item_name,
                        quantity: val.quantity,
                        item_price: val.item_price,
                        sub_total_price: val.sub_total_price,
                        shift_name: bill.shift_name // Include shift name for unique identification
                    });
                    totalSales += val.sub_total_price;
                } else {
                    // Update existing entry if found (same item name and shift)
                    items[itemIndex].quantity += val.quantity;
                    items[itemIndex].sub_total_price += val.sub_total_price;
                    totalSales += val.sub_total_price;
                }
    
                if (val.item_name.includes("Free Coffee") || val.item_name.includes("Voucher 25k")) {
                    minusServiceFee += val.sub_total_price * process.env.REACT_APP_PERCENTAGE_FEE_CARWASH;
                }
            });
        });
    
        return { items, totalSales, minusServiceFee, totalSalesByPaymentMethodWithServiceFee };
    };    
    
    const createDataPayload = (items, totalSales, totalServiceFee, totalTransaction, typeOfReport, startDate, endDate) => {
        const dateRange = typeOfReport === 'Custom Range'
            ? `${moment(startDate).format('DD/MM/YYYY')} - ${moment(endDate).format('DD/MM/YYYY')}`
            : moment(startDate).format('DD/MM/YYYY');
    
        return {
            payload: {
                data: {
                    detailSales: items.map(({ item_name, quantity, sub_total_price }) => ({
                        item_name,
                        quantity,
                        sub_total_price
                    })),
                    'Total Sales': totalSales,
                    'Total Service Fee': totalServiceFee,
                    'Total Transaction': totalTransaction
                },
                carwash: {
                    name: 'Car Wash Report',
                    type: typeOfReport,
                    date: dateRange
                }
            }
        };
    };
    
    const generatePDFReport = (startDate, endDate, totalSales, totalServiceFee, totalTransaction, chartImages, totalSalesByPaymentMethodWithServiceFee, items) => {
        const jspdf = new jsPDF();
    
        // Header Background Color and Company Logo
        jspdf.setFillColor(41, 128, 185); // Light blue color
        jspdf.rect(0, 0, jspdf.internal.pageSize.width, 40, 'F');
    
        // Add Company Logo
        const logoPath = '/carwash.png';
        try {
            jspdf.addImage(logoPath, 'PNG', 15, 5, 30, 30);
        } catch (error) {
            console.warn("Logo not found, skipping logo in PDF header.");
        }
    
        // Title and Company Information
        jspdf.setFontSize(18);
        jspdf.setTextColor(255, 255, 255); // White text for header
        jspdf.setFont("helvetica", "bold");
        jspdf.text("Sales Report - Car Wash", 50, 20);
    
        jspdf.setFontSize(12);
        jspdf.setFont("helvetica", "normal");
        jspdf.text("Car Wash Services Summary", 50, 28);
    
        // Additional Report Information
        jspdf.setTextColor(0, 0, 0); // Reset text color to black
        jspdf.setFontSize(10);
        const dateRangeText = `Date Range: ${moment(startDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")}`;
        const reportDateText = `Report Generated: ${moment().format("DD MMM YYYY, HH:mm")}`;
        jspdf.text(dateRangeText, 15, 55);
        jspdf.text(reportDateText, 15, 62);
    
        // Separator Line
        jspdf.setLineWidth(0.5);
        jspdf.setDrawColor(41, 128, 185);
        jspdf.line(15, 65, jspdf.internal.pageSize.width - 15, 65);
    
        // Summary Section
        jspdf.setFont("helvetica", "bold");
        jspdf.setFontSize(14);
        jspdf.text("Summary:", 15, 75);
    
        jspdf.setFont("helvetica", "normal");
        jspdf.setFontSize(12);
        jspdf.text(`Total Sales (excl. Service Fee): ${Currency(totalSales)}`, 15, 90);
        jspdf.text(`Service Fee Collected: ${Currency(totalServiceFee)}`, 15, 105);
        jspdf.text(`Total Transactions: ${totalTransaction}`, 15, 120);
    
        // Total by Payment Method with Conditional Service Fee
        jspdf.setFont("helvetica", "bold");
        jspdf.text("Total Sales by Payment Method (incl. 5% fee for eligible items):", 15, 135);
        let yPosition = 145;
        Object.entries(totalSalesByPaymentMethodWithServiceFee).forEach(([method, total]) => {
            jspdf.setFont("helvetica", "normal");
            jspdf.text(`- ${method}: Rp ${total.toLocaleString()}`, 25, yPosition);
            yPosition += 15;
        });
    
        // Detail Sales Section Title
        jspdf.setFont("helvetica", "bold");
        jspdf.text("Detail Sales:", 15, yPosition + 20);
    
        // Render Table for Detailed Sales
        const tableBody = items.map((item, index) => [
            index + 1,                    // No
            item.item_name,               // Item Name
            item.quantity,                // Quantity
            `Rp ${item.sub_total_price.toLocaleString()}`, // Sub Total Price
            item.shift_name               // Shift Name
        ]);
        
        // Define table headers
        const tableHeaders = [["No", "Item Name", "QTY", "Sub Total Price", "Shift"]];
        
        jspdf.autoTable({
            head: tableHeaders,
            body: tableBody,
            startY: yPosition + 30,
            theme: "striped",
            headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255] },
            styles: { font: "helvetica", fontSize: 10, cellPadding: 5 },
            columnStyles: {
                0: { cellWidth: 15 },  // No
                1: { cellWidth: 60 },  // Item Name
                2: { cellWidth: 20 },  // Quantity
                3: { cellWidth: 40 },  // Sub Total Price
                4: { cellWidth: 30 },  // Shift Name
            },
            pageBreak: 'auto',
        });

        const pageWidth = jspdf.internal.pageSize.width;
        const pageHeight = jspdf.internal.pageSize.height;
        const barChartMaxWidth = pageWidth - 20;
        const barChartMaxHeight = pageHeight / 3;
        const barChartAspectRatio = 16 / 9;
    
        for (const chartImage of chartImages) {
            let barChartWidth = barChartMaxWidth;
            let barChartHeight = barChartMaxWidth / barChartAspectRatio;
    
            if (barChartHeight > barChartMaxHeight) {
                barChartHeight = barChartMaxHeight;
                barChartWidth = barChartMaxHeight * barChartAspectRatio;
            }
    
            let barChartYPosition = jspdf.autoTable.previous.finalY + 10;
    
            if (barChartYPosition + barChartHeight > pageHeight - 20) {
                jspdf.addPage();
                barChartYPosition = 10;
            }
    
            const barChartXPosition = (pageWidth - barChartWidth) / 2;
            jspdf.addImage(chartImage, 'PNG', barChartXPosition, barChartYPosition, barChartWidth, barChartHeight);
        }

    
        // Save the PDF
        jspdf.save(`Report-Car Wash-${moment().format('DD-MMM-YYYY')}.pdf`);
    };
    
    
    const sendReport = async (payload) => {
        const response = await fetch(process.env.REACT_APP_API_URL_CARWASH + '/order/report', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        });
        console.log('Report sent:', response);
    };

    const  fetchOrdersByDateRange = async (startDate, endDate) => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL_CARWASH + '/report', {
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD')
            });
            return response.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    const onClickPrintInvoice = (orderItemsList, bill) => {
        printInvoice.printInvoiceInProgress(bill, '/carwash.png', process.env.REACT_APP_PERCENTAGE_FEE_CARWASH).then(value => {});
    };

    const onClickCompletePayment = async (orderItemsList, bill) => {

        const theMoney = prompt("Uang yang diberikan berapa? :");
        const dateTime = moment().format("DDMMYYYY");
        const billsData = localStorage.getItem("orderDataCarwash");
        const bills = JSON.parse(billsData);
        const billsDataForToday = bills[dateTime];
        const billsDataForTodayData = billsDataForToday.data;
    
        const billIndex = billsDataForTodayData.findIndex((val) => val.order_num === bill.order_num);
        const serviceFee = process.env.REACT_APP_PERCENTAGE_FEE_CARWASH;
        const theTotal = (billsDataForTodayData[billIndex].total * serviceFee) + billsDataForTodayData[billIndex].total;
        const theKembalian = theMoney - theTotal;

        if (theKembalian < 0) {
            alert("Uang yang diberikan tidak cukup.");
            return;
        }

        billsDataForTodayData[billIndex].status = 'paid';
        billsDataForTodayData[billIndex].dateclosebill = moment().format();
        billsDataForTodayData[billIndex].kembalian = theKembalian;
        billsDataForTodayData[billIndex].money = theMoney;

        try {
            const response = await sendApiRequest(`${process.env.REACT_APP_API_URL_CARWASH}/order`, 'PUT', {
                payload: {
                    order_num: billsDataForTodayData[billIndex].order_num,
                    order_items: billsDataForTodayData[billIndex].order_items,
                        status: billsDataForTodayData[billIndex].status,
                        total: billsDataForTodayData[billIndex].total,
                        date: billsDataForTodayData[billIndex].date,
                        dateclosebill: moment().format(),
                        notes: billsDataForTodayData[billIndex].note,
                        metode_pembayaran: paymentMethod.value
                    }
                });
    
                if (response.message === 'success') {
                    localStorage.setItem("orderDataCarwash", JSON.stringify(bills));
                    setShowModal(false);
                    setChildren('');
                    getBills();
    
                    // Simpan bill dan orderItemsList saat ini untuk digunakan dalam modal cetak
                    setCurrentBill(billsDataForTodayData[billIndex]);
                    setCurrentOrderItemsList(orderItemsList);
                    setShowPrintModal(true); // Tampilkan modal cetak
                } else {
                    alert('Gagal memperbarui pesanan di server. Silakan coba lagi.');
                }
            } catch (error) {
                console.error('Failed to update order on server:', error);
                alert('Gagal memperbarui pesanan di server. Silakan coba lagi.');
            }
        };
    
        const onClickAddOrder = (orderItemsList, bill) => {
            const orderNum = bill.order_num;
            setShowModal(false);
            setChildren(
                <div className='wrapper row'>
                    <MenuContent orderNum={orderNum} orderItemsList={orderItemsList} onUpdate={true} date={bill.date} notes={bill.notes} />
                </div>
            );
            setTitle('Menu');
            setSizeModal('xl');
            setStyleModal({ margin: '20', padding: '0' });
            setShowModal(true);
        };

        const updateOrderWithStatus = async (status, bill) => {
            const dateTime = moment().format("DDMMYYYY");
            const billsData = localStorage.getItem("orderDataCarwash");
            const bills = JSON.parse(billsData);
            const billsDataForToday = bills[dateTime];
            const billsDataForTodayData = billsDataForToday.data;
        
            const billIndex = billsDataForTodayData.findIndex((val) => val.order_num === bill.order_num);
            // let theTotal = (billsDataForTodayData[billIndex].total * 5/100) + billsDataForTodayData[billIndex].total;
    
            billsDataForTodayData[billIndex].status = 'paid';
            billsDataForTodayData[billIndex].dateclosebill = moment().format();
            billsDataForToday.data = billsDataForTodayData;
            bills[dateTime] = billsDataForToday;
            billsDataForTodayData[billIndex].kembalian = 0

            try {
                const response = await sendApiRequest(`${process.env.REACT_APP_API_URL_CARWASH}/order`, 'PUT', {
                payload: {
                    order_num: billsDataForTodayData[billIndex].order_num,
                    order_items: billsDataForTodayData[billIndex].order_items,
                    status: billsDataForTodayData[billIndex].status,
                    total: billsDataForTodayData[billIndex].total,
                    date: billsDataForTodayData[billIndex].date,
                    dateclosebill: moment().format(),
                    notes: billsDataForTodayData[billIndex].note,
                    metode_pembayaran: status
                    },
                    
                });
                console.log(response)
                if (response.message === 'success') {
                    localStorage.setItem("orderDataCarwash", JSON.stringify(bills));
                    setShowModal(false);
                    setChildren('');
                    getBills();
    
                    // Simpan bill dan orderItemsList saat ini untuk digunakan dalam modal cetak
                    setCurrentBill(billsDataForTodayData[billIndex]);
                    setCurrentOrderItemsList(orderItemsListParam);
                    setShowPrintModal(true); // Tampilkan modal cetak
                } else {
                    alert('Gagal memperbarui pesanan di server. Silakan coba lagi.');
                }
            } catch (error) {
                console.error('Failed to update order on server:', error);
                alert('Gagal memperbarui pesanan di server. Silakan coba lagi.');
            }
        }
    
        const showDetailOrder = (bill) => {
            console.log(bill)
            const orderItemsList = bill.order_items.map((item, index) => (
                <div key={index} className='row'>
                    <div className='col'>
                        <h6>{item.item_name}</h6>
                    </div>
                    <div className='col-2'>
                        <h6>{item.quantity}</h6>
                    </div>
                    <div className='col-4'>
                        <h6>@{Currency(item.item_price)}</h6>
                    </div>
                    {item.items && item.items.filter(itm => itm.item_type !== 'default').map((val, index) => (
                        <div key={index} className="col-12 p-0">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item d-flex justify-content-between align-items-center p-0 mx-4" style={{ maxHeight: '25px', marginBlock: '2px' }}>
                                    <span className="order-name" id={item.id}>{val.item_type}</span>
                                    <div className="order-side-price">(+) {Currency(val.item_price)}</div>
                                </li>
                            </ul>
                        </div>
                    ))}
                    <hr />
                </div>
            ));
    
            const serviceFeeRate = process.env.REACT_APP_PERCENTAGE_FEE_CARWASH;
            const totalWithServiceFee = bill.total + (bill.total * serviceFeeRate);
    
            setChildren(
                <div>
                    {bill.status === 'in progress' && (
                        <Button onClick={() => onClickAddOrder(orderItemsList, bill)} style={{ marginLeft: '10px', marginBottom: '10px' }}>
                            + Add Order
                        </Button>
                    )}
                    {orderItemsList}
                    <p>Total + Service Fee : <b>{Currency(totalWithServiceFee)}</b></p>
                    <p>Has Been Ordered <b>{freeOrderCarwash.find(data => data.order_num === bill.order_num)?.order_count || 0}</b> times</p>
                    {freeOrderCarwash.find(data => data.order_num === bill.order_num)?.is_free_order ? <p>Pembayaran Kali Ini <b>Gratis</b></p> : ''}
                    <h4>Notes :</h4>
                    {bill.notes ? bill.notes.map((note, index) => (
                        <div key={index} className='row'>
                            <div className='col-12'>
                                <h6>{note}</h6>
                            </div>
                        </div>
                    )) : <p>No notes</p>}
                    <Button variant="success" onClick={() => onClickPrintInvoice(orderItemsList, bill)}>Print</Button>
                    {bill.status === 'in progress' && (
                        <Button style={{ marginLeft: '10px' }} onClick={() => {
                            const checkOrder = freeOrderCarwash.filter(data => data.order_num == bill.order_num);
                            console.log(checkOrder, bill.order_num)
                            if (checkOrder[0].is_free_order && bill.order_items[0].item_name.includes('cuci')){
                                updateOrderWithStatus('free', bill);
                                return;
                            }
                            setOrderItemsListParam(orderItemsList);
                            setBillsParam(bill);
                            setShowModalPayment(true)
                        }}>
                            Complete Payment
                        </Button>
                    )}
                </div>
            );
    
            setTitle(`Bill No. ${bill.order_num} | ${bill.status.toUpperCase()}`);
            setShowModal(true);
        };
    
        const getBills = async () => {
            const dateTime = moment().format("DDMMYYYY");
            const billsData = localStorage.getItem("orderDataCarwash");
            const bills = JSON.parse(billsData);
            const billsDataForToday = bills[dateTime];
            setStateBills(billsDataForToday.data);
        }
    
        useEffect(() => {
            getBills();
        }, []);

        useEffect(() => {
            const getFreeOrderCarwash = JSON.parse(localStorage.getItem('free_order_carwash'));
            console.log(getFreeOrderCarwash)
            setFreeOrderCarwash(getFreeOrderCarwash);
        },[]);
    
        const handlePrintInvoice = async () => {
            if (currentBill && currentOrderItemsList.length > 0) {
                await printInvoice.printInvoiceInProgress(currentBill, '/carwash.png', process.env.REACT_APP_PERCENTAGE_FEE_CARWASH);
                setShowPrintModal(false); // Tutup modal setelah mencetak struk
            }
        };
    
        const renderPrintInvoiceModal = () => (
            <CenteredModal
                show={showPrintModal}
                onHide={() => setShowPrintModal(false)}
                title="Cetak Struk"
            >
                <p>Apakah Anda ingin mencetak struk untuk transaksi ini?</p>
                <Button variant="primary" onClick={handlePrintInvoice}>Ya, Cetak Struk</Button>
                <Button variant="secondary" onClick={() => setShowPrintModal(false)}>Tidak</Button>
            </CenteredModal>
        );

        
    
        return (
            <div className="container">
                <div>
                    <canvas id="salesChart" width="800" height="400" style={{display: 'none'}}></canvas>
                    <DateRangeModal show={showDateModal} handleClose={() => setDateShowModal(false)} handleDateChange={(start, end) => handleDateChange(start, end)} />
                </div>
                <h1 className="mt-4">Bills</h1>
                <Button className="mb-3" onClick={() => setDateShowModal(true)}>Print Daily Report</Button>
                <div className="row">
                    {stateBills.map((bill, index) => {
                    let bgColor;
                    switch (bill.status) {
                        case 'paid':
                            bgColor = '#2DCCFF';
                            break;
                        case 'in progress':
                            bgColor = '#90EE90';
                            break;
                        case 'cancelled':
                            bgColor = 'yellow';
                            break;
                        default:
                            bgColor = 'gray'; // Default color if no status matches
                    }
                    return (
                        <div className="col-md-4 mb-3" key={index}>
                            <div className="card" style={{ backgroundColor: bgColor }}>
                                <div className="card-body">
                                    <h5 className="card-title">Bill No: {bill.order_num}</h5>
                                    <p className="card-text">Status: <i>{bill.status}</i></p>
                                    <p className="card-text">Total: {Currency(bill.total)}</p>
                                    {bill.notes && bill.notes.length > 0 ? (
                                        <p className="card-text">Note: <b>{bill.notes[0]}</b></p>
                                    ) : (
                                        <p className="card-text" style={{ color: 'gray' }}>Note: nothing</p>
                                    )}
                                    <Button variant="primary" onClick={() => showDetailOrder(bill)}>Show Details</Button>
                                </div>
                            </div>
                        </div>
                    );
                    })}
                </div>
                <table id="tableX" style={{ display: 'none' }}></table>
                <CenteredModal
                    show={showModal}
                    onHide={() => setShowModal(false)}
                    title={title}
                    size={sizeModal}
                    style={styleModal}
                >
                    {children}
                </CenteredModal>
                {renderPrintInvoiceModal()}
                <PaymentMethodModal
                    show={showModalPayment}
                    onHide={() => setShowModalPayment(false)}
                    onConfirm={handleConfirmPaymentModal}
                />
            </div>
        );
    }
    
    export default BillsContentCarwash;