import React, { useEffect } from "react";
import { COLORS } from "../colors";

const Sidebar = ({height, active, changeActive}) => {
    useEffect(() => {
        document.querySelectorAll('.sidebar-active').forEach((item) => {
            item.style.backgroundColor = COLORS.$colorPallete2;
        });
    }, [active]);
    
    const menuItems = ['Kopi Agus', 'Car Wash', 'HeHe'];

    return (
        <nav className="col-md-2 sidebar-menu d-flex flex-column flex-shrink-0 p-3 text-white bg-dark fixed-top" style={{ position: 'sticky', width: '120px', height: '100%', borderTopRightRadius: '6px' }}>
            <hr />
            <ul className="nav nav-pills flex-column mb-auto">
                {menuItems.map(menu => (
                    <li className="nav-item" onClick={() => changeActive(menu)} key={menu}>
                        <a href="#" className={active === menu ? "sidebar-active nav-link" : "nav-link text-dark"} 
                           style={active === menu ? { backgroundColor: COLORS.secondary, borderRadius: '10px', padding: '20px 10px', margin: '5px 0' } : { backgroundColor: COLORS.backgroundLight, borderRadius: '10px', padding: '20px 10px', margin: '5px 0' }}>
                          {menu.charAt(0).toUpperCase() + menu.slice(1)}
                        </a>
                    </li>
                ))}
            </ul>
            <hr />
        </nav>
    );
}

export default Sidebar;
