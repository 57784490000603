import axios from 'axios';

async function sendApiRequest(url, method, body) {
  const options = {
    url, // The URL for the request
    method, // HTTP method (GET, POST, PUT, DELETE, etc.)
    headers: {
      'Content-Type': 'application/json', // You can add more headers if needed
    },
    withCredentials: true, // This allows cookies and credentials to be sent
  };

  // If it's not a GET request, include the body
  if (method !== 'GET') {
    options.data = body; // Axios uses `data` for request bodies
  }

  try {
    const response = await axios(options); // Make the request using Axios
    return response.data; // Return the response data
  } catch (error) {
    console.error('Network request failed', error);
    throw error; // Rethrow the error to handle it in the calling function
  }
}

export default sendApiRequest;
