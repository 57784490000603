import React, { useEffect, useState, useContext } from "react";
import { AppContext } from "../../Context";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

import Header from "../../components/header";
import Navbar from "../../components/navbar";
import Sidebar from "../../components/sidebar";
import MenuContent from "../../components/fnb/menu-content";
import BillsContent from "../../components/fnb/bills-content";
import '../../index.css';
import MenuContentCarWash from "../../components/carwash/menu-content";
import BillsContentCarwash from "../../components/carwash/bill-content";
import MenuContentCarWashWithCustomDate from "../../components/secretAdmin/menu-contents";

const DashboardMain = () => {
  const navigate = useNavigate();
  var [heightSidebar, setHeightSidebar] = useState(0);
  const [activeNavbar, setActiveNavbar] = useState('main');
  const [activeSidebar, setActiveSidebar] = useState('');
  const [menu, setMenu] = useState({});
  const [order, setOrder] = useState({
    data: {},
    total: 0,
  });

  async function getMenuFromDatabase(name) {
    let data;

    try {
        // Make a request to the API, sending the 'name' as a query parameter
        const response = await axios.get(process.env.REACT_APP_API_URL_MENU, {
          params: {
            menu_type: name
          }
        })

        if (!response.status == 200) {
            throw new Error(`Error fetching menu: ${response.statusText}`);
        }

        // Parse the response as JSON
        console.log(response)
        data = await response.data;
        
        // Set the menu state with the fetched data
        setMenu(data);
    } catch (error) {
        console.error('Error fetching menu:', error);
    }
}

  async function getMenu(name) {
    let data;
    if(name === 'Kopi Agus'){
      await getMenuFromDatabase('Food & Drinks');
    } else if(name === 'Car Wash'){
      await getMenuFromDatabase('Car Services');
    } else if (name === 'HeHe'){
      await getMenuFromDatabase('Car Services');
    }
  }

  useEffect(() => {
    const url = window.location.href;
    const regex = /[?&]name=([^&#]+)/;
    const match = url.match(regex);
    if (match) {
      const name = decodeURIComponent(match[1]);
      setActiveSidebar(name);
    } else {
      console.log("No name parameter found in the URL");
    }
  }, []);

  useEffect(() => {
    if (activeSidebar) {
      getMenu(activeSidebar);
    }
  }, [activeSidebar]);

  function handleResizeSidebar() {
    var countNavbar = document.getElementsByClassName("navbar").length;
    var heightNavbar = 0;
    for (let i = 0; i < countNavbar; i++) {
      heightNavbar += Math.round(document.getElementsByClassName("navbar")[i].clientHeight);
    }
    return window.innerHeight - heightNavbar;
  }

  const changeActiveNavbar = (active) => {
    setActiveNavbar(active);
  }

  const changeActiveSidebar = (menu) => {
    if (menu === 'Kopi Agus'){
      navigate('/fnb?name=Kopi Agus');
      window.location.reload();
    } else if(menu === 'Car Wash'){
      navigate('/carwash?name=Car Wash');
      window.location.reload();
    } else if(menu === 'HeHe'){
      navigate('/hehe?name=HeHe');
      window.location.reload();
    }
  }

  useEffect(() => {
    window.addEventListener("resize", () => {
      setHeightSidebar(handleResizeSidebar());
    });

    window.addEventListener("load", () => {
      setHeightSidebar(handleResizeSidebar());
    });

    return () => {
      window.removeEventListener("resize", handleResizeSidebar);
      window.removeEventListener("load", handleResizeSidebar);
    }
  }, []);

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh', backgroundColor: 'var(--background-light)' }}>
  <Header />
  <Navbar onClickMenu={changeActiveNavbar} />
  <AppContext.Provider value={{ menu: [menu, setMenu], order: [order, setOrder] }}>
    <div style={{ flex: '1' }}>
      {activeNavbar ? (
        activeNavbar === 'main' ? (
          <div className="wrapper row" style={{ margin: 0 }}>
            <Sidebar active={activeSidebar} height={heightSidebar} changeActive={changeActiveSidebar} style={{ backgroundColor: 'var(--background-light)' }} />
            {activeSidebar ? (
              activeSidebar === 'Kopi Agus' ? (
                <MenuContent height={heightSidebar} style={{ backgroundColor: 'var(--background-white)', color: 'var(--text-dark-gray)' }} />
              ) : activeSidebar === 'Car Wash' ? (
                <MenuContentCarWash height={heightSidebar} style={{ backgroundColor: 'var(--background-white)', color: 'var(--text-dark-gray)' }} />
              ) : activeSidebar === 'HeHe' ? (
                <MenuContentCarWashWithCustomDate height={heightSidebar} style={{ backgroundColor: 'var(--background-white)', color: 'var(--text-dark-gray)' }} />
              ) : null
            ) : null}
          </div>
        ) : activeNavbar === 'bills' ? (
          activeSidebar ? (
            activeSidebar === 'Kopi Agus' ? (
              <BillsContent style={{ backgroundColor: 'var(--background-white)', color: 'var(--text-dark-gray)' }} />
            ) : activeSidebar === 'Car Wash' ? (
              <BillsContentCarwash style={{ backgroundColor: 'var(--background-white)', color: 'var(--text-dark-gray)' }} />
            ) : activeSidebar === 'HeHe' ? (
              <MenuContentCarWashWithCustomDate height={heightSidebar} style={{ backgroundColor: 'var(--background-white)', color: 'var(--text-dark-gray)' }} />
            ) : null
          ) : null
        ) : activeNavbar === 'kitchen' ? (
          null
        ) : null
      ) : null}
    </div>
  </AppContext.Provider>
  <footer style={{ backgroundColor: 'var(--background-dark)', color: 'var(--text-white)', padding: '1rem', textAlign: 'center', fontSize: '12px' }}>
    <p>© 2024 Syddad-Tiwi. All rights reserved.</p>
  </footer>
</div>

  );
}

export default DashboardMain;
