import React, { useEffect } from 'react';

const Toast = ({ message, onClose }) => {
  useEffect(() => {
    // Automatically close the toast after 3 seconds
    const timer = setTimeout(() => {
      onClose();
    }, 500);

    return () => {
      clearTimeout(timer); // Clear timeout if the component unmounts
    };
  }, [onClose]);

  return (
    <div style={toastStyles}>
      {message}
    </div>
  );
};

const toastStyles = {
  position: 'fixed',
  bottom: '20px',
  right: '20px',
  backgroundColor: '#333',
  color: '#fff',
  padding: '10px 20px',
  borderRadius: '5px',
  boxShadow: '0px 0px 10px rgba(0,0,0,0.3)',
  zIndex: 1000,
};

export default Toast;
