import React, { useEffect } from "react";
import { COLORS } from "../colors";
import '../style.css';
import moment from "moment";

const Header = ({props}) => {
  const [time, setTime] = React.useState('');
  useEffect(() => {
    const interval2 = setInterval(() => {   
      setTime(moment().format('dddd, DD MMMM YYYY hh:mm:ss'));
    }, 1000);
    return () => clearInterval(interval2);
  }, []);
  // header for PoS contain name store, type store on the left and last open and last closed on the right
  return (
    <header style={{ backgroundColor: COLORS.primaryDark, color: COLORS.backgroundWhite, padding: '10px 20px', display: 'flex', alignItems: 'center', justifyContent: 'space-between', fontSize: '14px' }}>
      <span>{time}</span>
      <span>POSKasir &gt;&lt;  <span style={{ fontStyle: 'italic', backgroundColor: COLORS.primary }}>HeHe</span></span>
    </header>
  )
}

export default Header;
