import React, { useEffect, useState, useContext } from 'react';
import { AppContext } from '../../Context';

import DateRangeModal from '../date-range-modal';
import CenteredModal from "../../components/modal";
import PaymentMethodModal from '../paymentMethodModal';
import FormVoucherModal from '../formVoucherModal';

import { COLORS } from '../../colors';
import Currency from "../../utils/currency";
import printInvoice from '../../utils/printInvoice';
import sendApiRequest from '../../utils/sendReq';
import generateSalesChart from '../../utils/generateSalesChart';
import createPaymentMethodPieChart from '../../utils/createPieChartPaymentMethod';

import moment from "moment";
import { Card, Button,Modal } from 'react-bootstrap';
import axios from 'axios';

import { useNavigate } from 'react-router-dom';
import MenuContent from './menu-content';
import jsPDF from 'jspdf';
import 'jspdf-autotable';

const BillsContent = (props) => {
    const { order } = useContext(AppContext);
    const [stateBills, setStateBills] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [showDateModal, setDateShowModal] = useState(false);
    const [children, setChildren] = useState('');
    const [title, setTitle] = useState('');
    const [sizeModal, setSizeModal] = useState('');
    const [styleModal, setStyleModal] = useState({});
    const [showPrintModal, setShowPrintModal] = useState(false);
    const [currentBill, setCurrentBill] = useState(null);
    const [currentOrderItemsList, setCurrentOrderItemsList] = useState([]);
    const [currOrderNum, setCurrOrderNum] = useState(0);

    //FORM VOUCHER MODAL
    const [showFormVoucherModal, setShowFormVoucherModal] = useState(false);
    const [vouchersData, setVouchersData] = useState([]);
    const handleShowFormVoucherModal = async () => {
        // Fetch Voucher
        const response = await axios.get(`${process.env.REACT_APP_API_URL}/voucher`);
        console.log(response);
        setVouchersData(response.data.data)

        setShowFormVoucherModal(true);
    };

    const handleFormVoucherSubmit = async (data) => {
        console.log("HEHE", data.billData);
        const bill = data.billData
        const voucher = data;
        delete voucher.billData;
        console.log(voucher);

        let confirmVoucher = confirm("Yakin Pakai Voucher?");
        if (confirmVoucher) {
            const dateTime = moment().format("DDMMYYYY");
            const billsData = localStorage.getItem("orderData");
            const bills = JSON.parse(billsData);
            const billsDataForToday = bills[dateTime];
            const billsDataForTodayData = billsDataForToday.data;
        
            const billIndex = billsDataForTodayData.findIndex((val) => val.order_num === bill.order_num);
            // let theTotal = (billsDataForTodayData[billIndex].total * 5/100) + billsDataForTodayData[billIndex].total;
 
            billsDataForTodayData[billIndex].status = 'paid';
            billsDataForTodayData[billIndex].dateclosebill = moment().format();
            billsDataForToday.data = billsDataForTodayData;
            bills[dateTime] = billsDataForToday;
            billsDataForTodayData[billIndex].kembalian = 0

            try {
                const response = await sendApiRequest(`${process.env.REACT_APP_API_URL_FNB}/order`, 'PUT', {
                payload: {
                    order_num: billsDataForTodayData[billIndex].order_num,
                    order_items: billsDataForTodayData[billIndex].order_items,
                    status: billsDataForTodayData[billIndex].status,
                    total: billsDataForTodayData[billIndex].total,
                    date: billsDataForTodayData[billIndex].date,
                    dateclosebill: moment().format(),
                    notes: billsDataForTodayData[billIndex].note,
                    metode_pembayaran: "voucher",
                    voucher : voucher
                    },
                    
                });
                console.log(response)
                if (response.message === 'success') {
                    localStorage.setItem("orderData", JSON.stringify(bills));
                    setShowModal(false);
                    setChildren('');
                    getBills();
    
                    // Simpan bill dan orderItemsList saat ini untuk digunakan dalam modal cetak
                    setCurrentBill(billsDataForTodayData[billIndex]);
                    setCurrentOrderItemsList(orderItemsListParam);
                    setShowPrintModal(true); // Tampilkan modal cetak
                } else {
                    alert('Gagal memperbarui pesanan di server. Silakan coba lagi.');
                }
            } catch (error) {
                console.error('Failed to update order on server:', error);
                alert('Gagal memperbarui pesanan di server. Silakan coba lagi.');
            }
        }
        
        
    }
    //FORM VOUCHER MODAL

    const router = useNavigate();

    const onClickPrintInvoice = (orderItemsList, bill) => {
        var blob = '';
        console.log(orderItemsList);
        console.log(bill);
        const buffer = printInvoice.printInvoiceInProgress(bill, '/kopiagus.png', process.env.REACT_APP_PERCENTAGE_FEE_FNB).then(value => {});
    };

    const [orderItemsListParam, setOrderItemsListParam] = useState(null);
    const [billsParam, setBillsParam] = useState(null)

    const [showModalPayment, setShowModalPayment] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState(null);

    const handleConfirmPaymentModal = (method) => {
        if (method && method.value) {
          setPaymentMethod(method);
          setShowModalPayment(false);
          onClickCompletePayment(orderItemsListParam, billsParam);
          console.log('Selected payment method:', method);
        } else {
          console.error("No valid payment method selected.");
        }
      };

    const onClickCompletePayment = async (orderItemsList, bill) => {
        console.log(orderItemsList, bill)
        let theMoney = prompt("Uang yg dikasih berapa? :");
        const dateTime = moment().format("DDMMYYYY");
        const billsData = localStorage.getItem("orderData");
        const bills = JSON.parse(billsData);
        const billsDataForToday = bills[dateTime];
        const billsDataForTodayData = billsDataForToday.data;
    
        const billIndex = billsDataForTodayData.findIndex((val) => val.order_num === bill.order_num);
        let theTotal = (billsDataForTodayData[billIndex].total * 5/100) + billsDataForTodayData[billIndex].total;
        let theKembalian = theMoney - theTotal;
        if(theKembalian<0){
            alert("Uang nya gk cukupp");
            return;
        }

        billsDataForTodayData[billIndex].status = 'paid';
        billsDataForTodayData[billIndex].dateclosebill = moment().format();
        billsDataForToday.data = billsDataForTodayData;
        bills[dateTime] = billsDataForToday;
        billsDataForTodayData[billIndex].kembalian = theKembalian;
        billsDataForTodayData[billIndex].money = theMoney;

        try {
            const response = await sendApiRequest(`${process.env.REACT_APP_API_URL_FNB}/order`, 'PUT', {
            payload: {
                order_num: billsDataForTodayData[billIndex].order_num,
                order_items: billsDataForTodayData[billIndex].order_items,
                    status: billsDataForTodayData[billIndex].status,
                    total: billsDataForTodayData[billIndex].total,
                    date: billsDataForTodayData[billIndex].date,
                    dateclosebill: moment().format(),
                    notes: billsDataForTodayData[billIndex].note,
                    metode_pembayaran: paymentMethod.value
                }
            });

            if (response.message === 'success') {
                localStorage.setItem("orderData", JSON.stringify(bills));
                setShowModal(false);
                setChildren('');
                getBills();

                // Simpan bill dan orderItemsList saat ini untuk digunakan dalam modal cetak
                setCurrentBill(billsDataForTodayData[billIndex]);
                setCurrentOrderItemsList(orderItemsList);
                setShowPrintModal(true); // Tampilkan modal cetak
            } else {
                alert('Gagal memperbarui pesanan di server. Silakan coba lagi.');
            }
        } catch (error) {
            console.error('Failed to update order on server:', error);
            alert('Gagal memperbarui pesanan di server. Silakan coba lagi.');
        }
    };

    const onClickAddOrder = (orderItemsList, bill) => {
        const orderNum = bill.order_num;
        setShowModal(false);
        setChildren(
            <div className='wrapper row'>
                <MenuContent orderNum={orderNum} orderItemsList={orderItemsList} onUpdate={true} date={bill.date} notes={bill.notes}/>
            </div>
        );
        setTitle('Menu');
        setSizeModal('xl');
        setStyleModal({ margin: '20', padding: '0' });
        setShowModal(true);
    };

    const showDetailOrder = (bill) => {
        console.log(bill);
        const orderItems = bill.order_items;
        const orderItemsList = orderItems.map((item, index) => {
            console.log(item);
            return (
                <div key={index} className='row'>
                    <div className='col'>
                        <h6>{item.item_name}</h6>
                    </div>
                    <div className='col-2'>
                        <h6>{item.quantity}</h6>
                    </div>
                    <div className='col-4'>
                        <h6>@{Currency(item.item_price)}</h6>
                    </div>
                    {item.items ? item.items.filter((itm) => itm.item_type !== 'default').map((val, index) => {
                        console.log(val);
                        return (
                            <div key={index} className="col-12 p-0">
                                <ul className="list-group list-group-flush">
                                    <li className="list-group-item d-flex justify-content-between align-items-center p-0 mx-4" style={{ maxHeight: '25px', marginBlock: '2px' }}>
                                        <span className="order-name" id={item.id}>{val.item_type}</span>
                                        <div className="order-side-price">(+) {Currency(val.item_price)}</div>
                                    </li>
                                </ul>
                            </div>
                        );
                    }) : null}
                    <hr></hr>
                </div>
            );
        });

        async function toSetChildren(orderItemsList, bill){
            return (
                <div>
                    {bill.status === 'in progress' ?
                    <Button onClick={() => onClickAddOrder(orderItemsList, bill)} style={{ marginLeft: '10px', marginBottom: '10px' }}>
                        + Add Order
                    </Button>
                    : null}
                    {orderItemsList}
                    <p>Total + Service Fee : <b>{Currency((bill.total * 5/100) + bill.total)}</b></p>
                    <h4>Notes :</h4>
                    {bill.notes ? bill.notes.map((note, index) => {
                        return (
                            <div key={index} className='row'>
                                <div className='col-12'>
                                    <h6>{note}</h6>
                                </div>
                            </div>
                        );
                    }) : null}
                    <Button style={{ backgroundColor: '#90EE90', borderColor: '#90EE90' }} onClick={() => onClickPrintInvoice(orderItemsList, bill)}>Print</Button>
                    {bill.status === 'in progress' ?
                    <Button style={{ marginLeft: '10px' }} onClick={() => {
                        setOrderItemsListParam(orderItemsList);
                        setBillsParam(bill);
                        setShowModalPayment(true)
                    }}>Complete Payment</Button>
                    : null}
                    {bill.status === 'in progress' ?
                    <Button style={{ marginLeft: '10px', backgroundColor: 'rgb(0 255 181)', borderColor: 'rgb(0 255 181)', color: 'black' }} onClick={() => {
                        setBillsParam(bill);
                        setOrderItemsListParam(orderItemsList);
                        console.log("bill", bill);
                        handleShowFormVoucherModal();
                    }}>Use Voucher</Button>
                    : null}
                </div>
            );
        }

        toSetChildren(orderItemsList, bill).then(value => { setChildren(value) });
        setTitle(`Bill No. ${bill.order_num} | ${bill.status.toUpperCase()}`);
        setShowModal(true);
    };

    const [dateRange, setDateRange] = useState({ startDate: null, endDate: null });

    const handleDateChange = (startDate, endDate) => {
        setDateRange({ startDate, endDate });
        onClickPrintReport(startDate, endDate);
    };

    const createPayload = (datePayload, typeOfReport, items, totalSales, totalServiceFee, totalTransaction) => ({
        payload: {
            data: {
                detailSales: items.map((item) => ({
                    item_name: item.item_name,
                    quantity: item.quantity,
                    sub_total_price: item.sub_total_price,
                    components: item.components
                })),
                "Total Sales": totalSales,
                "Total Service Fee": totalServiceFee,
                "Total Transaction": totalTransaction,
            },
            fnb: {
                name: "Report Sales of Kopi Agus",
                type: typeOfReport,
                date: moment(datePayload).format("DD/MM/YYYY"),
            },
        },
    });
    
    const handlePrintInvoice = async () => {
        if (currentBill && currentOrderItemsList.length > 0) {
            await printInvoice.printInvoiceInProgress(currentBill, '/kopiagus.png', process.env.REACT_APP_PERCENTAGE_FEE_FNB);
            setShowPrintModal(false); // Tutup modal setelah mencetak struk
        }
    };

    const renderPrintInvoiceModal = () => (
        <CenteredModal
            show={showPrintModal}
            onHide={() => setShowPrintModal(false)}
            title="Cetak Struk"
        >
            <p>Apakah Anda ingin mencetak struk untuk transaksi ini?</p>
            <Button variant="primary" onClick={handlePrintInvoice}>Ya, Cetak Struk</Button>
            <Button variant="secondary" onClick={() => setShowPrintModal(false)}>Tidak</Button>
        </CenteredModal>
    );

    const onClickPrintReport = async (startDate, endDate) => {
        const startDateFormatted = moment(startDate).format('DDMMYYYY');
        const endDateFormatted = moment(endDate).format('DDMMYYYY');
        const currentDateFormatted = moment().format('DDMMYYYY');
        const isToday = startDateFormatted === currentDateFormatted && endDateFormatted === currentDateFormatted;
    
        let datePayload = isToday ? startDate : `${startDate} - ${endDate}`;
        const typeOfReport = isToday ? "Daily" : "Custom Range";
    
        // Fetch data based on date range
        const billsDataForSelectedRange = isToday ?
            JSON.parse(localStorage.getItem("orderData"))?.[currentDateFormatted]?.data || []
            : await fetchOrdersByDateRange(startDate, endDate);
    
        if (billsDataForSelectedRange.length === 0) {
            alert("No orders found for the selected date range");
            return;
        }
    
        // Generate report details
        const { totalSales, items, totalServiceFee, totalWithServiceFee, totalSalesByPaymentMethod } = processOrderData(billsDataForSelectedRange);
        const totalTransaction = billsDataForSelectedRange.filter((order) => order.status !== "pending").length;
    
        // Generate PDF
        await generatePDFReport(startDate, endDate, items, totalSales, totalServiceFee, totalTransaction, totalWithServiceFee, totalSalesByPaymentMethod);
    
        // Payload for API
        const payloadReq = createPayload(datePayload, typeOfReport, items, totalSales, totalServiceFee, totalTransaction);
        console.log(payloadReq.payload);
    
        // Send payload to API
        const response = await axios.post(`${process.env.REACT_APP_API_URL_FNB}/order/report`, payloadReq);
        console.log(response);
    };
    
    // Process order data with 5% service fee for eligible items
    const processOrderData = (billsData) => {
        let totalSales = 0;
        const items = [];
        const totalPriceByPaymentMethod = {};
        let totalServiceFee = 0;
        const totalWithServiceFee = {};
        const totalSalesByPaymentMethod = {}; // <-- Add this line
    
        const updateOrAddItem = (item, componentsCount) => {
            const itemIndex = items.findIndex((itm) => itm.id === item.id);
    
            // Only apply service fee for eligible items
            if (!["Free Coffee", "Voucher 25k"].includes(item.item_name)) {
                totalServiceFee += item.sub_total_price * 0.05;
            }
    
            if (itemIndex === -1) {
                items.push({ ...item, components: componentsCount });
            } else {
                items[itemIndex].quantity += item.quantity;
                items[itemIndex].sub_total_price += item.sub_total_price;
                mergeComponents(items[itemIndex].components, componentsCount);
            }
    
            totalSales += item.sub_total_price;
        };
    
        // Calculate total by payment method and accumulate totals for the pie chart
        billsData.forEach((order) => {
            const paymentMethod = order.metode_pembayaran;
            
            order.order_items.forEach((item) => {
                const componentsCount = item.components ? { [item.components]: 1 } : {};
                updateOrAddItem(item, componentsCount);
    
                // Calculate total with and without service fee by payment method
                const itemTotalWithServiceFee = ["Free Coffee", "Voucher 25k"].includes(item.item_name)
                    ? item.sub_total_price
                    : item.sub_total_price * 1.05;
    
                totalWithServiceFee[paymentMethod] = (totalWithServiceFee[paymentMethod] || 0) + itemTotalWithServiceFee;
                totalPriceByPaymentMethod[paymentMethod] = (totalPriceByPaymentMethod[paymentMethod] || 0) + item.sub_total_price;
    
                // Accumulate total sales by payment method for the pie chart
                totalSalesByPaymentMethod[paymentMethod] = (totalSalesByPaymentMethod[paymentMethod] || 0) + item.sub_total_price;
            });
        });
    
        return { totalSales, items, totalServiceFee, totalWithServiceFee, totalSalesByPaymentMethod }; // <-- Include totalSalesByPaymentMethod in return
    };
    
    // Helper function to merge components
    const mergeComponents = (existingComponents, newComponents) => {
        for (const component in newComponents) {
            existingComponents[component] = (existingComponents[component] || 0) + newComponents[component];
        }
    };
    
    // Generate PDF report
    const generatePDFReport = async (startDate, endDate, items, totalSales, totalServiceFee, totalTransaction, totalWithServiceFee, totalSalesByPaymentMethod) => {
        const doc = new jsPDF();
    
        doc.setFillColor(41, 128, 185);
        doc.rect(0, 0, doc.internal.pageSize.width, 40, 'F');
    
        // Add Company Logo
        const logoPath = '/kopiagus.png';
        try {
            doc.addImage(logoPath, 'PNG', 15, 5, 30, 30);
        } catch (error) {
            console.warn("Logo not found, skipping logo in PDF header.");
        }
    
        // Title and Company Information
        doc.setFontSize(18);
        doc.setTextColor(255, 255, 255);
        doc.setFont("helvetica", "bold");
        doc.text("Sales Report - AS Kopi Agus", 50, 20);
    
        doc.setFontSize(12);
        doc.setFont("helvetica", "normal");
        doc.text("Specialty Coffee & Beverages", 50, 28);
    
        // Additional Report Information
        doc.setTextColor(0, 0, 0);
        doc.setFontSize(10);
        const dateRangeText = `Date Range: ${moment(startDate).format("DD MMM YYYY")} - ${moment(endDate).format("DD MMM YYYY")}`;
        const reportDateText = `Report Generated: ${moment().format("DD MMM YYYY, HH:mm")}`;
        doc.text(dateRangeText, 15, 55);
        doc.text(reportDateText, 15, 62);
    
        // Add a separator line below the header
        doc.setLineWidth(0.5);
        doc.setDrawColor(41, 128, 185);
        doc.line(15, 65, doc.internal.pageSize.width - 15, 65);
    
        // Summary Section Title
        doc.setFont("helvetica", "bold");
        doc.setFontSize(14);
        doc.text("Summary:", 15, 75);
        
        // Rest of the PDF content
        doc.setFont("helvetica", "normal");
        doc.setFontSize(12);
        const formattedTotalSales = new Intl.NumberFormat('id-ID', { style: 'currency', currency: 'IDR' }).format(totalSales);
        doc.text(`Total Sales (excl. Service Fee): ${formattedTotalSales}`, 15, 82.5);
        doc.text(`Service Fee Collected: Rp ${totalServiceFee.toLocaleString()}`, 15, 90);
        doc.text(`Total Transactions: ${totalTransaction}`, 15, 97.5);
    
        // Total by Payment Method with Conditional Service Fee
        doc.setFont("helvetica", "bold");
        doc.text("Total Sales by Payment Method (incl. 5% fee for eligible items):", 15, 110);
        let yPosition = 117.5;
        Object.entries(totalWithServiceFee).forEach(([method, total]) => {
            doc.setFont("helvetica", "normal");
            doc.text(`- ${method}: Rp ${total.toLocaleString()}`, 25, yPosition);
            yPosition += 7.5;
        });
    
        // Detailed Sales Table
        doc.setFont("helvetica", "bold");
        doc.text("Detail Sales:", 15, yPosition + 7.5);
    
        doc.autoTable({
            head: [["No", "Item Name", "Quantity", "Sub Total Price", "Components"]],
            body: items.map((item, index) => [
                index + 1,
                item.item_name,
                item.quantity,
                `Rp ${item.sub_total_price.toLocaleString()}`,
                formatComponents(item.components),
            ]),
            startY: yPosition + 15,
            theme: "striped",
            headStyles: { fillColor: [41, 128, 185], textColor: [255, 255, 255] },
            styles: { font: "helvetica", fontSize: 10, cellPadding: 5 },
            columnStyles: {
                0: { cellWidth: 15 },
                1: { cellWidth: 60 },
                2: { cellWidth: 20 },
                3: { cellWidth: 30 },
                4: { cellWidth: 60 },
            },
            pageBreak: 'auto',
        });
    
        const pageWidth = doc.internal.pageSize.width;
        const pageHeight = doc.internal.pageSize.height;
        const chartMaxWidth = pageWidth - 60;
        const chartMaxHeight = pageHeight / 3;
    
        // Aspect ratios
        const barChartAspectRatio = 160 / 90;
        const pieChartAspectRatio = 1;
    
        // Calculate dimensions for each chart
        let barChartWidth = chartMaxWidth;
        let barChartHeight = chartMaxWidth / barChartAspectRatio;
        if (barChartHeight > chartMaxHeight) {
            barChartHeight = chartMaxHeight;
            barChartWidth = chartMaxHeight * barChartAspectRatio;
        }
    
        let pieChartWidth = chartMaxWidth * 0.6;
        let pieChartHeight = pieChartWidth / pieChartAspectRatio;
        if (pieChartHeight > chartMaxHeight) {
            pieChartHeight = chartMaxHeight;
            pieChartWidth = chartMaxHeight * pieChartAspectRatio;
        }
    
        // Calculate the position for the first chart (bar chart)
        let chartYPosition = doc.autoTable.previous.finalY + 20;
    
        // If bar chart doesn't fit, add a new page
        if (chartYPosition + barChartHeight > pageHeight - 20) {
            doc.addPage();
            chartYPosition = 20; // Reset yPosition for new page
        }
    
        const barChartImage = await generateSalesChart(items);
        doc.addImage(barChartImage, "PNG", (pageWidth - barChartWidth) / 2, chartYPosition, barChartWidth, barChartHeight);
    
        // Calculate position for the second chart (pie chart)
        chartYPosition += barChartHeight + 20;
    
        // If pie chart doesn't fit, add a new page
        if (chartYPosition + pieChartHeight > pageHeight - 20) {
            doc.addPage();
            chartYPosition = 20; // Reset yPosition for new page
        }
    
        const pieChartImage = await createPaymentMethodPieChart(totalSalesByPaymentMethod);
        doc.addImage(pieChartImage, "PNG", (pageWidth - pieChartWidth) / 2, chartYPosition, pieChartWidth, pieChartHeight);    
    
        const pageCount = doc.internal.getNumberOfPages();
        for (let i = 1; i <= pageCount; i++) {
            doc.setPage(i);
            doc.setFontSize(10);
            doc.text(`Page ${i} of ${pageCount}`, doc.internal.pageSize.width - 20, doc.internal.pageSize.height - 10, { align: 'right' });
        }
    
        doc.save(`Sales_Report_${moment().format("DD-MMM-YYYY")}.pdf`);
    };    
    
    // Format components as string
    const formatComponents = (components) => {
        if (!components || Object.keys(components).length === 0) {
            return "N/A";
        }
        return Object.entries(components)
            .map(([key, value]) => `${key}: ${value}`)
            .join(", ");
    };

    const fetchOrdersByDateRange = async (startDate, endDate) => {
        try {
            const response = await axios.post(process.env.REACT_APP_API_URL_FNB + '/report', {
                startDate: moment(startDate).format('YYYY-MM-DD'),
                endDate: moment(endDate).format('YYYY-MM-DD')
            });
            console.log("DWWDW", response.data)
            return response.data;
        } catch (error) {
            console.error(error);
            return [];
        }
    };

    const getBills = async () => {
        const dateTime = moment().format("DDMMYYYY");
        const billsData = localStorage.getItem("orderData");
        const bills = JSON.parse(billsData);
        const billsDataForToday = bills[dateTime];
        setStateBills(billsDataForToday.data);
        console.log(billsDataForToday);
    }

    useEffect(() => {
        getBills();
    }, []);

    return (
        <div className="container">
            <div>
                {/* <Button onClick={() => setDateShowModal(true)}>Select Date Range</Button> */}
                <canvas id="salesChart" width="800" height="400" style={{display: 'none'}}></canvas>
                <canvas id="canvas-pie-chart-payment-method" width="200" height="200" style={{display: 'none'}}></canvas>
                <DateRangeModal show={showDateModal} handleClose={() => setDateShowModal(false)} handleDateChange={handleDateChange} />
            </div>
            <h1 className="mt-4">Bills</h1>
            <Button className="mb-3" onClick={() => setDateShowModal(true)}>Print Daily Report</Button>
            <div className="row">
                {stateBills.map((bill, index) => {
                let bgColor;
                switch (bill.status) {
                    case 'paid':
                    bgColor = '#2DCCFF';
                    break;
                    case 'in progress':
                    bgColor = '#90EE90';
                    break;
                    case 'cancelled':
                    bgColor = 'yellow';
                    break;
                    default:
                    bgColor = 'gray'; // Default color if no status matches
                }
                return (
                    <div className="col-md-4 mb-3" key={index}>
                    <div className="card" style={{ backgroundColor: bgColor }}>
                        <div className="card-body">
                        <h5 className="card-title">Bill No: {bill.order_num}</h5>
                        <p className="card-text">Status: <i>{bill.status}</i></p>
                        <p className="card-text">Total: {Currency(bill.total)}</p>
                        {bill.notes && bill.notes.length > 0 ? (
                            <p className="card-text">Note: <b>{bill.notes[0]}</b></p>
                        ) : (
                            <p className="card-text" style={{ color: 'gray' }}>Note: nothing</p>
                        )}
                        <Button variant="primary" onClick={() => showDetailOrder(bill)}>Show Details</Button>
                        </div>
                    </div>
                    </div>
                );
                })}
            </div>
            <table id="tableX" style={{ display: 'none' }}></table>
            <CenteredModal
                show={showModal}
                onHide={() => setShowModal(false)}
                title={title}
                size={sizeModal}
                style={styleModal}
            >
                {children}
            </CenteredModal>
            {renderPrintInvoiceModal()}
            <PaymentMethodModal
                show={showModalPayment}
                onHide={() => setShowModalPayment(false)}
                onConfirm={handleConfirmPaymentModal}
            />
            <FormVoucherModal
                showModal={showFormVoucherModal}
                setShowModal={setShowFormVoucherModal}
                onSubmit={handleFormVoucherSubmit}
                vouchersData={vouchersData}
                billData={billsParam}
            />

        </div>

    );
}

export default BillsContent;