import { useNavigate } from "react-router-dom";
import { Button } from "react-bootstrap";
import './Home.css'; // Import a CSS file for custom styles

const Home = () => {
  const navigate = useNavigate();

  const shops = [
    {
      name: "Kopi Agus"
    },
    {
      name: "Car Wash"
    }
  ];

  const handleCardClick = (shopName) => {
    if (shopName === "Kopi Agus") {
      navigate(`/fnb?name=Kopi Agus`);
    } else if (shopName === "Car Wash") {
      navigate(`/carwash?name=Car Wash`);
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center min-vh-100 bg-dark text-white">
      <div className="container">
        <div className="row">
          <div className="col-12 text-center mb-4">
            <h1>Home</h1>
          </div>
        </div>
        <div className="row justify-content-center">
          {shops.map((shop, index) => {
            let bgColor;
            switch (shop.status) {
              case 'paid':
                bgColor = '#2DCCFF';
                break;
              case 'in progress':
                bgColor = '#90EE90';
                break;
              case 'cancelled':
                bgColor = 'yellow';
                break;
              default:
                bgColor = 'gray'; // Default color if no status matches
            }
            return (
              <div className="col-md-4 mb-3" key={index}>
                <div 
                  className="card card-hover" 
                  style={{ backgroundColor: bgColor }} 
                  onClick={() => handleCardClick(shop.name)}
                >
                  <div className="card-body">
                    <h5 className="card-title">{shop.name}</h5>
                    <p className="card-text">Status: <i>{shop.status}</i></p>
                    <p className="card-text">Total: {shop.total}</p>
                    {shop.notes && shop.notes.length > 0 ? (
                      <p className="card-text">Note: <b>{shop.notes}</b></p>
                    ) : (
                      <p className="card-text" style={{ color: 'gray' }}>Note: nothing</p>
                    )}
                    <Button variant="primary">Show Details</Button>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Home;