import React, { useEffect } from 'react';
import { COLORS } from '../colors';

const Navbar = ({onClickMenu}) => {
    const [active, setActive] = React.useState('main');

    const changeActive = (active) => {
        setActive(active);
    }

    useEffect(() => {
        document.querySelectorAll('.navbar-active').forEach((item) => {
            item.style.backgroundColor = COLORS.$colorPallete2;
        });
        document.querySelectorAll('.navbar-nonactive').forEach((item) => {
            item.style.backgroundColor = 'white';
        });
    }, []);

    return (
        <nav className="navbar navbar-expand-lg" style={{ backgroundColor: COLORS.primary, padding: '10px', top: 0 }}>
        <div className="container-fluid">
          <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarFnb" aria-controls="navbarFnb" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse justify-content-center" id="navbarFnb">
            <ul className="navbar-nav nav-pills">
              {['main', 'bills', 'kitchen'].map(menu => (
                <li className="nav-item" onClick={() => { onClickMenu(menu); changeActive(menu); }} key={menu}>
                  <a className={active === menu ? "nav-link active navbar-active text-white" : "nav-link navbar-nonactive"} 
                     style={active === menu ? { backgroundColor: COLORS.secondary, borderRadius: '20px', padding: '10px 30px', margin: '0 5px' } : { backgroundColor: COLORS.backgroundWhite, color: COLORS.textDark, borderRadius: '20px', padding: '10px 30px', margin: '0 5px' }} 
                     href="#">{menu.charAt(0).toUpperCase() + menu.slice(1)}</a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </nav>
    );
}

export default Navbar;