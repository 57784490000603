import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';

let chartInstance = null;

// Predefined colors with distinct shades
const predefinedColors = [
    "#4e73df", // Blue
    "#1cc88a", // Green
    "#36b9cc", // Teal
    "#f6c23e", // Yellow
    "#e74a3b", // Red
    "#858796", // Gray
    "#fd7e14", // Orange
    "#6610f2", // Purple
    "#20c997", // Cyan
    "#fdcb6e"  // Light Yellow
];

// Function to create a pie chart image for payment method breakdown
const createPaymentMethodPieChart = (totalSalesByPaymentMethod) => {
    // Remove the existing canvas if it exists
    const existingCanvas = document.getElementById("canvas-pie-chart-payment-method");
    if (existingCanvas) {
        existingCanvas.remove();
    }

    // Create a new canvas element
    const canvas = document.createElement("canvas");
    canvas.id = "canvas-pie-chart-payment-method";
    canvas.width = 300;
    canvas.height = 300;
    document.body.appendChild(canvas);

    const ctx = canvas.getContext('2d');
    const total = Object.values(totalSalesByPaymentMethod).reduce((sum, value) => sum + value, 0);

    // Use predefined colors or fallback to random if there are more segments than colors
    const backgroundColors = Object.keys(totalSalesByPaymentMethod).map((_, index) => 
        predefinedColors[index % predefinedColors.length]
    );

    Chart.register(ChartDataLabels); // Register the datalabels plugin
    chartInstance = new Chart(ctx, {
        type: "pie",
        data: {
            labels: Object.keys(totalSalesByPaymentMethod).map((method) => {
                const amount = totalSalesByPaymentMethod[method];
                const percentage = ((amount / total) * 100).toFixed(1);
                return `${method}: ${percentage}%`; // Add percentage to each label
            }),
            datasets: [{
                data: Object.values(totalSalesByPaymentMethod),
                backgroundColor: backgroundColors,
            }]
        },
        options: {
            plugins: {
                legend: {
                    position: "top",
                    labels: {
                        font: { size: 30 }, // Use your original font size
                        padding: 15 // Add padding between legend items
                    }
                },
                datalabels: {
                    color: '#fff', // White text on data labels
                    font: {
                        weight: 'bold',
                        size: 30 // Keep the original size for readability
                    },
                    formatter: (value, ctx) => {
                        const percentage = ((value / total) * 100).toFixed(1);
                        return `${percentage}%`;
                    }
                }
            }
        }
    });

    // Use a timeout to allow the chart to render fully before converting it to an image
    return new Promise((resolve) => {
        setTimeout(() => {
            const chartImage = canvas.toDataURL("image/png");
            resolve(chartImage);
        }, 1000); // Adjust the delay if needed
    });
};

export default createPaymentMethodPieChart;
