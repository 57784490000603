import React from 'react';
import { Modal, Button } from 'react-bootstrap';

const InfoModal = ({ showModalInfo, setShowModalInfo, type, message }) => {
    const getModalStyle = () => {
        switch (type) {
            case 'error':
                return 'bg-danger text-white';  // Red background for errors
            case 'warning':
                return 'bg-warning text-dark';  // Yellow background for warnings
            case 'info':
            default:
                return 'bg-info text-white';    // Blue background for info
        }
    };

    const getModalIcon = () => {
        switch (type) {
            case 'error':
                return <i className="bi bi-exclamation-circle"></i>;  // Error icon
            case 'warning':
                return <i className="bi bi-exclamation-triangle"></i>;  // Warning icon
            case 'info':
            default:
                return <i className="bi bi-info-circle"></i>;  // Information icon
        }
    };

    return (
        <Modal show={showModalInfo} onHide={() => setShowModalInfo(false)} centered>
            <Modal.Header closeButton className={getModalStyle()}>
                <Modal.Title>
                    {getModalIcon()} {type.charAt(0).toUpperCase() + type.slice(1)} Message
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p>{message}</p>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={() => setShowModalInfo(false)}>
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default InfoModal;
