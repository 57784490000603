import { useState } from "react";
import { useNavigate } from "react-router-dom";
// import bcrypt from "bcryptjs";
// import { useCookies } from "react-cookie";
// import jwt from "jsonwebtoken";

const Login = () => {
    const router = useNavigate();
    const [form, setForm] = useState({
        username: "",
        password: "",
        rememberMe: false
    });
    // const [cookies, setCookie] = useCookies(["token"]);
    
    const formHandler = (e) => {
        console.log(e.target.id, e.target.value, e.target.checked)
        // if checkbox
        if(e.target.id === "rememberMe") {
            setForm({
                ...form,
                [e.target.id]: e.target.checked
            });
            return;
        }

        setForm({
            ...form,
            [e.target.id]: e.target.value
        });
        return;
    };

    const loginHandler = (data) => {
        const payload = {
            username : data.username,
            password : data.password,
            rememberMe : data.rememberMe
        }
        const response = fetch(process.env.REACT_APP_API_URL + "/auth/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify(payload)
        });
        console.log(response);
        if (response.access_token !== null) {
            setCookie("accessToken", response.access_token, { path: "/" });
            router("/dashboard/order");
        }else{
            alert("Login failed");
            return;
        }
        
    };

    return (
        <section className="login-page text-center text-lg-start bg-dark">
        <div className="card mb-3 bg-dark text-white">
            <div className="row g-0 d-flex align-items-center">
            <div className="col-lg-4 d-none d-lg-flex">
                <img src="https://mdbootstrap.com/img/new/ecommerce/vertical/004.jpg" alt="Trendy Pants and Shoes"
                className="w-100 rounded-t-5 rounded-tr-lg-0 rounded-bl-lg-5" />
            </div>
            <div className="col-lg-8">
                <div className="card-body py-5 px-md-5">

                <form>
                
                    <div className="form-outline mb-4 rounded-6">
                    <input type="username" id="username" onChange={(e) => formHandler(e)} className="form-control" />
                    <label className="form-label" id="username">Username</label>
                    </div>

            
                    <div className="form-outline mb-4 rounded-6">
                    <input type="password" id="password" onChange={(e) => formHandler(e)} className="form-control" />
                    <label className="form-label" id="password">Password</label>
                    </div>

                
                    <div className="row mb-4">
                    <div className="col d-flex justify-content-center">
                    
                        <div className="form-check">
                        <input className="form-check-input"  onChange={(e) => formHandler(e)} type="checkbox" value="" id="rememberMe" />
                        <label className="form-check-label" id="remember-me"> Remember me </label>
                        </div>
                    </div>

                    <div className="col">

                        <a href="#!">Forgot password?</a>
                    </div>
                    </div>

            
                    <button type="button" onClick={() => loginHandler(form)} className="btn btn-primary btn-lg btn-block">Sign in</button>

                </form>

                </div>
            </div>
            </div>
        </div>
    </section>
    );
}

export default Login;
