import Chart from "chart.js/auto";
import ChartDataLabels from 'chartjs-plugin-datalabels';

let chartInstances = [];

function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
        color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
}

function chunkArray(array, chunkSize) {
    const result = [];
    for (let i = 0; i < array.length; i += chunkSize) {
        result.push(array.slice(i, i + chunkSize));
    }
    return result;
}

function mergeItemsByName(items) {
    const mergedItems = {};

    items.forEach(item => {
        // Use only item_name as the key to aggregate by name
        const itemName = item.item_name;

        if (!mergedItems[itemName]) {
            // Initialize if the item doesn't exist in the aggregated list
            mergedItems[itemName] = {
                ...item, 
                quantity: 0 // Start with zero quantity for summing
            };
        }

        // Sum up quantities of items with the same name
        mergedItems[itemName].quantity += item.quantity;
    });

    // Convert the merged items object back to an array
    return Object.values(mergedItems);
}

async function generateSalesCharts(items, chunkSize = 10) {
    // Merge items by name
    const mergedItems = mergeItemsByName(items);
    console.log("Merged Items:", mergedItems);

    // Now, continue with the original chart generation logic
    // Destroy any existing chart instances
    chartInstances.forEach(chart => chart.destroy());
    chartInstances = [];

    // Break merged items into chunks
    const itemChunks = chunkArray(mergedItems, chunkSize);
    const chartImages = [];

    for (const [index, chunk] of itemChunks.entries()) {
        const labels = chunk.map(item => item.item_name);
        const data = chunk.map(item => item.quantity);
        const backgroundColors = chunk.map((_, i) => {
            const colorScheme = [
                '#4e73df', '#1cc88a', '#36b9cc', '#f6c23e', 
                '#e74a3b', '#858796', '#5a5c69', '#f8f9fc', 
                '#eaeaea', '#ff6384'
            ];
            return colorScheme[i % colorScheme.length] || getRandomColor();
        });

        const canvas = document.createElement("canvas");
        canvas.width = 800;
        canvas.height = 400;
        document.body.appendChild(canvas);

        const ctx = canvas.getContext("2d");
        
        Chart.register(ChartDataLabels);
        const chartInstance = new Chart(ctx, {
            type: "bar",
            data: {
                labels: labels,
                datasets: [{
                    label: 'Quantity Sold',
                    data: data,
                    backgroundColor: backgroundColors,
                    borderColor: backgroundColors,
                    borderWidth: 1
                }]
            },
            options: {
                plugins: {
                    datalabels: {
                        anchor: 'end',
                        align: 'end',
                        color: '#000',
                        font: {
                            weight: 'bold',
                            size: 24
                        },
                        formatter: (value) => value,
                    },
                    legend: {
                        position: 'bottom',
                        labels: {
                            font: { size: 24 },
                            padding: 10,
                        }
                    }
                },
                layout: {
                    padding: {
                        top: 10,
                        bottom: 10,
                    }
                },
                scales: {
                    x: {
                        display: true,
                        ticks: {
                            autoSkip: false,
                            maxRotation: 45,
                            minRotation: 0,
                            font: {
                                size: 24
                            }
                        }
                    },
                    y: {
                        beginAtZero: true,
                        ticks: {
                            font: {
                                size: 24
                            }
                        }
                    }
                }
            }
        });

        chartInstances.push(chartInstance);

        await new Promise(resolve => setTimeout(resolve, 500));
        chartImages.push(canvas.toDataURL("image/png"));
        
        canvas.remove();
    }

    return chartImages;
}

export default generateSalesCharts;
