import React, { useState, useEffect } from 'react';
import { Modal, Button, Form, Alert } from 'react-bootstrap';
import Currency from "../utils/currency";

const FormVoucherModal = ({ showModal, setShowModal, onSubmit, vouchersData, billData }) => {
    const [vouchersList, setVouchersList] = useState([]);
    const [formData, setFormData] = useState({
        voucher_id: '',
        description: '',
        amount_of_people: '',
        amount_used: '',
        remaining_total_price: ''
    });
    const [error, setError] = useState('');

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value,
        });
        if (name === 'amount_used') {
            setError(''); // Clear the error when the user updates the amount
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        const amountUsed = formData.amount_used
    
        // Validation: Check if amount_used is greater than the total price
        if (amountUsed > billData.total) {
            setError(
                `Jumlah yang digunakan (${Currency(amountUsed)}) tidak boleh lebih besar dari total (${Currency(billData.total)}).`
            );
            return;
        }
        
        console.log(billData)
        // Create the submission object
        const submissionData = {
            ...formData,
            remaining_total_price: billData.total - amountUsed,
            billData, // Add billData explicitly here
        };
    
        console.log("Submission Data:", submissionData); // Debugging: Check the final data structure
    
        // Pass the combined object to the onSubmit function
        onSubmit(submissionData);
    
        // Reset form data
        setFormData({
            voucher_id: '',
            description: '',
            amount_of_people: '',
            amount_used: '',
            remaining_total_price: '',
        });
        setError(''); // Clear any previous errors
        // Close the modal after submission
        setShowModal(false);
    };

    useEffect(() => {
        console.log(billData)
        setVouchersList(vouchersData);
    }, [vouchersData]);

    useEffect(() => {
        console.log(billData);
        setFormData({
            ...formData,
            amount_used: billData?.total
        })
    }, [billData]);

    return (
        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title>Input Voucher Form</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <p><i>{`Total :`} <b>{Currency(billData?.total)}</b></i></p>
                {/* <p><i>{`Service Fee : ${Currency(billData.total * 5 / 100)}`}</i></p> */}

                {error && <Alert variant="danger">{error}</Alert>} {/* Display error if any */}

                <Form onSubmit={handleSubmit}>
                    <Form.Group controlId="formVoucherSelect">
                        <Form.Label>Pilih Voucher *</Form.Label>
                        <Form.Control
                            as="select"
                            name="voucher_id"
                            value={formData.voucher_id}
                            onChange={handleChange}
                            required
                        >
                            <option value="">-- Pilih Voucher --</option>
                            {vouchersList.map((voucher) => (
                                <option key={voucher.voucher_id} value={voucher.voucher_id}>
                                    {voucher.description} (Remaining: {Currency(voucher.remaining_budget)})
                                </option>
                            ))}
                        </Form.Control>
                    </Form.Group>

                    <Form.Group controlId="formDescription">
                        <Form.Label>Deskripsikan Penggunaan Voucher *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="Tamu dari Bapak Budi"
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formAmountOfPeople">
                        <Form.Label>Ada Berapa Tamu ? *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="ex. 6, 9, 4, 20"
                            name="amount_of_people"
                            value={formData.amount_of_people}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Form.Group controlId="formAmountUsed">
                        <Form.Label>Berapa Rupiah yang Akan Digunakan ? *</Form.Label>
                        <Form.Control
                            type="text"
                            placeholder="20000"
                            name="amount_used"
                            value={formData.amount_used}
                            onChange={handleChange}
                            required
                        />
                    </Form.Group>

                    <Button className="mt-2" variant="primary" type="submit">
                        Submit
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default FormVoucherModal;
