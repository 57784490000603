import moment from "moment";

const calculateDaysUntilBirthday = (date_of_birth) => {
    // Parse the member's birth date
    const birthDate = moment(date_of_birth, 'YYYY-MM-DD');
    
    // Get the current date without time (set time to 00:00:00)
    const today = moment().startOf('day');

    // Set this year's birthday without time
    let nextBirthday = moment(birthDate).year(today.year()).startOf('day');

    // If today's date is after or the same as this year's birthday, increment the year
    if (today.isAfter(nextBirthday)) {
        nextBirthday.add(1, 'year');
    }

    // Calculate the next age
    const currentAge = today.year() - birthDate.year();
    const nextAge = nextBirthday.year() - birthDate.year();

    // Calculate the difference in days between today and the next birthday
    const daysUntilBirthday = nextBirthday.diff(today, 'days');

    // Return the result in the desired format: "X Tahun dalam Y hari lagi"
    return `${nextAge} Tahun dalam ${daysUntilBirthday} hari lagi`;
};

export { calculateDaysUntilBirthday }